<template>
  <v-layout :align-center="true" wrap class="gateways-modal">
    <!--Show when loading-->
    <v-flex v-if="loadingProcess" mt-10 mb-10 text-center>
      <v-progress-circular
        :size="40"
        color="primary"
        class="mb-2"
        indeterminate
      />
      <br />
      <b>{{ $t("packs.almostDone") }}</b>
    </v-flex>
    <!--Show when not deposit and enough balance for pay-->
    <v-flex
      v-else-if="
        transaction === null &&
        enoughBalanceFunds &&
        invoice.tag !== 'deposit_funds'
      "
    >
      <div class="title-text mb-8">
        {{ $t("gateway.orderConfirmation") }}
      </div>
      <div class="description-text mb-8">
        {{
          $t("packs.from_balance", {
            amount: formatMoneyWithCurrency(invoice.total, {
              currencySymbol: balance.currencySymbol,
              currency: balance.currency,
            }),
          })
        }}
      </div>
      <v-btn
        class="primary pay-button"
        height="56"
        block
        :loading="loading"
        :disabled="loading"
        @click="payFromBalance()"
      >
        {{ $t("gateway.pay") }}
        {{
          formatMoneyWithCurrency(invoice.total, {
            currency: invoice.currency,
          })
        }}
      </v-btn>
    </v-flex>
    <!--Show when deposit or not enough balance-->
    <v-flex
      v-else-if="
        transaction === null &&
        (!enoughBalanceFunds || invoice.tag === 'deposit_funds')
      "
    >
      <div
        v-if="invoice && $ls.get('config').payment_acceptance"
        class="gateway-card"
      >
        <template v-if="invoice.tag !== 'deposit_funds'">
          <div class="ma-0 mb-8 title-text">
            {{ $t("gateway.refill") }}
            {{
              formatMoneyWithCurrency(invoiceDiff, {
                currency: invoice.currency,
              })
            }}
          </div>
          <span v-if="balance?.available" class="description-text">
            {{
              $t("gateway.insufficientFunds", {
                balance: formatMoneyWithCurrency(balance.available, {
                  currencySymbol: balance.currencySymbol,
                  currency: balance.currency,
                }),
                total: formatMoneyWithCurrency(invoice.total, {
                  currency: invoice.currency,
                }),
              })
            }}
          </span>
        </template>
        <span v-else class="ma-0 title-text">
          {{ $t("gateway.refill") }}
          {{
            formatMoneyWithCurrency(invoice.total, {
              currency: invoice.currency,
            })
          }}
        </span>
        <v-layout wrap ma-0 mt-8>
          <v-flex>
            <div class="methods">
              <template
                v-if="
                  !selectedGatewayObj || selectedGatewayObj.type === 'crypto'
                "
              >
                <div class="method-wrapper">
                  <v-img :src="btcIcon" alt="cc" max-width="24" />
                </div>
                <div class="method-wrapper">
                  <v-img :src="ethIcon" alt="cc" max-width="24" />
                </div>
                <div class="method-wrapper">
                  <v-img :src="usdtIcon" alt="cc" max-width="24" />
                </div>
              </template>
              <template
                v-if="!selectedGatewayObj || selectedGatewayObj.type === 'fiat'"
              >
                <div class="method-wrapper">
                  <v-img :src="visaIcon" alt="cc" max-width="31" />
                </div>
                <div class="method-wrapper">
                  <v-img :src="mcIcon" alt="cc" max-width="24" />
                </div>
              </template>
            </div>
          </v-flex>
          <v-flex ma-0 mt-6 xs12>
            <v-select
              v-model.trim="selectedGateway"
              outlined
              :hide-details="
                selectedGateway ? (externalPayment ? false : 'auto') : false
              "
              :error-messages="
                error && !selectedGateway ? $t('gateway.chooseGateway') : null
              "
              class="mt-3"
              :items="allowedGateways"
              :item-text="'name'"
              :item-value="'module'"
              name="gateways"
              @change="recalculateInvoiceWithFee"
            >
              <template slot="label">
                <b>{{ $t("gateway.selectGateway") }}</b>
              </template>
            </v-select>
            <div
              v-if="loadingCurrencies"
              class="d-flex justify-center mt-3 mb-6"
            >
              <v-progress-circular :size="40" color="primary" indeterminate />
            </div>
            <v-select
              v-else-if="showAllowedCurrencies"
              v-model.trim="selectedCurrency"
              outlined
              :error-messages="
                error && !selectedCurrency
                  ? $t('gateway.choosePaymentCurrency')
                  : null
              "
              class="mt-3"
              :items="gateways[selectedGateway].allowedCurrencies"
              :item-text="'name'"
              :item-value="gateways[selectedGateway].allowedCurrencies.value"
              name="currency"
              @change="recalculateInvoiceWithFee"
            >
              <template slot="label">
                <b>{{ $t("withdrawals.selectCurrency") }}</b>
              </template>
              <template slot="selection" slot-scope="data">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name" />
                  <small class="text-neutral">{{
                    data.item.description
                  }}</small>
                </v-list-item-content>
              </template>
              <template slot="item" slot-scope="data">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name" />
                  <br />
                  <small class="text-neutral">{{
                    data.item.description
                  }}</small>
                </v-list-item-content>
              </template>
            </v-select>
            <v-select
              v-else-if="selectedGateway === 'bank_transfer'"
              v-model.trim="selectGatewayMethod"
              outlined
              :items="gatewayBankTransferOption"
              :item-text="'name'"
              :item-value="'key'"
              :label="$t('withdrawals.selectDepositMethodCurrency') + '*'"
              name="selectGatewayMethod"
              :error-messages="SelectedGatewayMethodErrors"
              @input="$v.selectGatewayMethod.$touch()"
              @blur="$v.selectGatewayMethod.$touch()"
            />
            <div
              v-else-if="selectedGateway && !externalPayment"
              class="unable-to-create mt-3 mb-6"
            >
              <span>
                {{ $t("gateway.unable") }}
              </span>
            </div>
          </v-flex>

          <v-flex xs12 ma-0 mt-2>
            <v-btn
              class="primary pay-button"
              height="56"
              block
              :loading="loading"
              :disabled="
                loading ||
                loadingCurrencies ||
                (!showAllowedCurrencies && !externalPayment)
              "
              @click="pay()"
            >
              {{ $t("gateway.pay") }}
              {{
                formatMoneyWithCurrency(
                  invoice.tag === "deposit_funds" ? invoice.total : invoiceDiff,
                  {
                    currency: invoice.currency,
                  }
                )
              }}
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
      <div v-else class="mt-5 mb-5 text-center">
        {{ $t("gateway.notAvaliable") }}
        <p v-if="$ls.get('config').telegram_support_id">
          <br />
          <a
            target="_blank"
            :href="`https://t.me/` + $ls.get('config').telegram_support_id"
            ><b>https://t.me/{{ $ls.get("config").telegram_support_id }}</b></a
          >
        </p>
      </div>
    </v-flex>
    <!--Show when transaction created-->
    <gateways-fiat
      v-else-if="showBankTransfer"
      :invoice="invoice"
      :method-currency="selectGatewayMethod"
      :bank-transfer-config="bankTransferConfig"
      @closeDialog="removeTransactionHandler"
    />
    <gateway
      v-else-if="transaction"
      :transaction="transaction"
      :currency="selectedCurrencyObj.name"
      :protocol="selectedCurrencyObj.description"
      @backToStep2="removeTransactionHandler"
    />
  </v-layout>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import INVOICE_QUERY from "@/graphql/queries/Invoice.gql";
import PAY_FROM_BALANCE from "@/graphql/mutations/PayFromBalance.gql";
import COINPAYMENTS_CONFIG_QUERY from "@/graphql/queries/gateways/CoinPaymentsConfig.gql";
import PAYPORT_CONFIG_QUERY from "@/graphql/queries/gateways/PayportLink.gql";
import STRIPE_CONFIG_QUERY from "@/graphql/queries/gateways/StripeLink.gql";
import PRODAMUS_CONFIG_QUERY from "@/graphql/queries/gateways/ProdamusLink.gql";
import CPAY_CONFIG_QUERY from "@/graphql/queries/gateways/CpayConfig.gql";
import BANK_TRANSFER_CONFIG_QUERY from "@/graphql/queries/BankTransferConfig.gql";
import WL_GATEWAYS from "@/graphql/queries/gateways/WhiteLabelGateways.gql";
import COINPAYMENTS_CREATE_TRANSACTION_MUTATION from "@/graphql/mutations/gateways/CoinPaymentsCreateTransaction.gql";
import SET_INVOICE_GATEWAY from "@/graphql/mutations/SetInvoiceGateway.gql";
import CPAY_CREATE_TRANSACTION_MUTATION from "@/graphql/mutations/gateways/CPayCreateTransaction.gql";
import Gateway from "@/components/core/PaymentProcess/steps/Gateway.vue";
import GatewaysFiat from "@/components/core/PaymentProcess/steps/GatewaysFiat.vue";
import usdtIcon from "@/images/crypto/usdt.svg";
import btcIcon from "@/images/crypto/btc.svg";
import ethIcon from "@/images/crypto/eth.svg";
import mcIcon from "@/images/fiat/mc.svg";
import visaIcon from "@/images/fiat/visa.svg";
import { errorHandler } from "@/plugins/mixins";
import { formatMoneyWithCurrency } from "@/plugins/formatters";
import { requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  name: "ChooseGateway",
  components: {
    Gateway,
    GatewaysFiat,
  },
  mixins: [validationMixin],
  props: {
    invoiceId: {
      type: [String, Number, null],
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      usdtIcon,
      btcIcon,
      ethIcon,
      mcIcon,
      visaIcon,
      selectedCurrency: null,
      selectedGateway: null,
      selectGatewayMethod: null,
      transaction: null,
      error: false,
      loading: false,
      showBankTransfer: false
    };
  },
  validations() {
    return {
      selectGatewayMethod: {
        required: requiredIf(() => this.selectedGateway === "bank_transfer"),
      },
    };
  },
  computed: {
    ...mapGetters({
      getUser: "user/getUser",
      getFilteredWallets: "user/getFilteredWallets",
      getCurrency: "app/getCurrency",
    }),
    loadingProcess() {
      return (this.$apollo.loading && !this.selectedGateway) || this.loading;
    },
    loadingCurrencies() {
      return this.selectedGateway && this.$apollo.loading;
    },
    showAllowedCurrencies() {
      return (
        this.selectedGateway &&
        this.gateways[this.selectedGateway].allowedCurrencies &&
        this.gateways[this.selectedGateway].allowedCurrencies.length
      );
    },
    externalPayment() {
      return (
        this.selectedGateway && this.gateways[this.selectedGateway].external
      );
    },
    whiteLabelId() {
      return this.$ls && this.$ls.get("config").uuid;
    },
    selectedGatewayObj() {
      return this.allowedGateways.find(
        (el) => el.module === this.selectedGateway
      );
    },
    selectedCurrencyObj() {
      return this.gateways[this.selectedGateway].allowedCurrencies.find(
        ({ value }) => value === this.selectedCurrency
      );
    },
    gateways() {
      return {
        coinpayments: {
          name: "coinpayments",
          allowedCurrencies: (this.coinPaymentsConfig || []).map((item) => ({
            ...item,
            value: item.name,
          })),
          transaction: this.createCoinPaymentsTransaction,
        },
        cpay: {
          name: "cpay",
          allowedCurrencies: (this.cpayConfig || []).map((item) => ({
            ...item,
            value: item.id,
          })),
          transaction: this.createCPayTransaction,
        },
        prodamus: {
          name: "prodamus",
          external: true,
          allowedCurrencies: [],
          transaction: this.openProdamusForm,
        },
        payport: {
          name: "payport",
          external: true,
          allowedCurrencies: [],
          transaction: this.openPayportForm,
        },
        stripe: {
          name: "stripe",
          external: true,
          allowedCurrencies: [],
          transaction: this.openStripeForm,
        },
        bank_transfer: {
          name: "bank_transfer",
          external: true,
          allowedCurrencies: [],
          transaction: this.openBanksTransferForm,
        },
      };
    },
    allowedGateways() {
      return (
        this.whiteLabelGateways
          ? this.whiteLabelGateways.filter(({ status }) => status === "enabled")
          : []
      )
        .map((item) => ({
          ...item,
          name: item.display_name || item.name,
        }))
        .filter(({ hidden_in_form }) => !hidden_in_form);
    },
    balance() {
      return this.getFilteredWallets?.find?.(
        (o) => o.currency === this.invoice.currency
      );
    },
    enoughBalanceFunds() {
      return this.balance?.available - this.invoice.total >= 0;
    },
    invoiceDiff() {
      const getBalance = this.balance ? this.balance?.available : 0;
      let diff = this.invoice.total - getBalance ?? 0;
      return diff.toFixed(2);
    },
    gatewayBankTransferOption() {
      return this.bankTransferConfig
        ? [
            this.bankTransferConfig.card_number && {
              name: this.$t("withdrawals.transferCard"),
              key: "card",
            },
            this.bankTransferConfig.iban && {
              name: this.$t("withdrawals.nationalBankTransfer"),
              key: "bank",
            },
            this.bankTransferConfig.iban && {
              name: this.$t("withdrawals.bankTransfer"),
              key: "swift",
            },
          ]
        : [];
    },
    SelectedGatewayMethodErrors() {
      const errors = [];
      if (!this.$v.selectGatewayMethod.$dirty) return errors;
      !this.$v.selectGatewayMethod.required &&
        errors.push(this.$t("aff.reqField"));
      return errors;
    },
  },
  mounted() {
    this.setPaymentsStep(2);
  },
  beforeDestroy() {
    this.$apollo.queries.invoice.stopPolling();
  },
  methods: {
    formatMoneyWithCurrency,
    ...mapMutations({
      setPaymentsStep: "app/setPaymentStep",
      updateUser: "user/updateUser",
    }),
    async recalculateInvoiceWithFee() {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: SET_INVOICE_GATEWAY,
          variables: {
            whitelabel_uuid: this.whiteLabelId,
            invoice_id: this.invoice.id,
            gateway: this.selectedGateway,
            ...(this.selectedCurrencyObj?.network
              ? {
                  network:
                    this.selectedGateway === "cpay"
                      ? this.selectedCurrencyObj?.node_type
                      : this.selectedCurrencyObj?.network,
                }
              : {}),
          },
        });
        this.invoice = data?.setInvoiceGateway || this.invoice;
      } catch (e) {
        errorHandler(e);
      }
    },
    removeTransactionHandler() {
      this.setPaymentsStep(2);
      this.transaction = null;
      this.showBankTransfer = false;
    },
    async pay() {
      if (this.selectedGateway === "bank_transfer") {
        this.$v.$touch();
        if (this.$v.$invalid) return;
      }
      if (this.externalPayment) {
        await this.gateways[this.selectedGateway].transaction();
      }
      if (
        !this.selectedGateway ||
        (this.selectedGateway !== "bank_transfer" && !this.selectedCurrency)
      ) {
        this.error = true;
        return;
      }
      this.transaction = await this.gateways[
        this.selectedGateway
      ].transaction();
      this.gateway = this.selectedGateway;
    },
    async payFromBalance() {
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: PAY_FROM_BALANCE,
          variables: {
            invoice_id: this.invoice.id,
          },
        });
        this.onInvoicePaid(this.invoice.tag);
      } catch (e) {
        this.errorHandler(e);
      }
      this.loading = false;
    },
    onInvoicePaid(invoiceTag) {
      this.$emit("closeDialog");
      this.$router.push("/thankyou/" + invoiceTag);
    },
    async createCoinPaymentsTransaction() {
      try {
        this.loading = true;
        const { data } = await this.$apollo.mutate({
          mutation: COINPAYMENTS_CREATE_TRANSACTION_MUTATION,
          variables: {
            invoice_id: this.invoice.id,
            currency: this.selectedCurrency,
          },
        });
        this.loading = false;
        return data.coinPaymentsCreateTransaction;
      } catch (e) {
        this.errorHandler(e);
        this.loading = false;
      }
    },
    async createCPayTransaction() {
      this.loading = true;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: CPAY_CREATE_TRANSACTION_MUTATION,
          variables: {
            invoice_id: this.invoice.id,
            currency_id: this.selectedCurrency,
          },
        });
        this.loading = false;
        return data.cpayCreateTransaction;
      } catch (e) {
        this.errorHandler(e);
        this.loading = false;
        // FOR TEST ONLY
        // return {amount: 12, address:'awdadwadw'};
      }
    },
    async openProdamusForm() {
      try {
        this.loading = true;
        window.location.href = this.prodamusLink.link;
      } catch (e) {
        this.errorHandler(e);
        this.loading = false;
      }
    },
    async openPayportForm() {
      try {
        this.loading = true;
        window.location.href = this.payportLink.link;
      } catch (e) {
        this.errorHandler(e);
        this.loading = false;
      }
    },
    async openStripeForm() {
      try {
        this.loading = true;
      } catch (e) {
        this.errorHandler(e);
        this.loading = false;
      }
    },
    async openBanksTransferForm() {
      try {
        this.loading = true;
        this.showBankTransfer = true;
        this.loading = false;
      } catch (e) {
        this.errorHandler(e);
        this.loading = false;
      }
    },
  },
  apollo: {
    whiteLabelGateways: {
      fetchPolicy: "network-only",
      query() {
        return WL_GATEWAYS;
      },
      variables() {
        {
          return {
            whitelabel_uuid: this.whiteLabelId,
          };
        }
      },
      skip() {
        return !this.whiteLabelId;
      },
    },
    coinPaymentsConfig: {
      query: COINPAYMENTS_CONFIG_QUERY,
      skip() {
        return this.selectedGateway !== "coinpayments";
      },
    },
    cpayConfig: {
      query: CPAY_CONFIG_QUERY,
      skip() {
        return this.selectedGateway !== "cpay";
      },
    },
    prodamusLink: {
      query: PRODAMUS_CONFIG_QUERY,
      variables() {
        return {
          invoice_id: this.invoiceId || this.$route.params.id,
        };
      },
      skip() {
        return this.selectedGateway !== "prodamus";
      },
    },
    payportLink: {
      query: PAYPORT_CONFIG_QUERY,
      variables() {
        return {
          invoice_id: this.invoiceId || this.$route.params.id,
        };
      },
      skip() {
        return this.selectedGateway !== "payport";
      },
    },
    createStripePaymentLink: {
      query: STRIPE_CONFIG_QUERY,
      variables() {
        return {
          invoice_id: this.invoiceId || this.$route.params.id,
        };
      },
      skip() {
        return this.selectedGateway !== "stripe";
      },
      result(data) {
        if (!data?.data) return;
        window.location.href = data.data.createStripePaymentLink.link;
      }
    },
    bankTransferConfig: {
      query: BANK_TRANSFER_CONFIG_QUERY,
      skip() {
        return this.selectedGateway !== "bank_transfer";
      },
    },
    invoice: {
      query: INVOICE_QUERY,
      pollInterval: 5 * 1000,
      variables() {
        return {
          id: this.invoiceId || this.$route.params.id,
        };
      },
      result(data) {
        if (data.loading === false) {
          if (data.data.invoice.status === "paid") {
            this.updateUser();
            this.onInvoicePaid(data.data.invoice.tag);
          }
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.title-text {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.description-text {
  font-size: 13px;
  line-height: 16px;
}

.pay-button {
  font-size: 15px !important;
}

.gateway-card {
  .v-card {
    padding: 15px !important;
    margin: 10px;

    .crypto {
      display: flex;
      gap: 24px;
    }
  }

  .methods {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .method-wrapper {
      border-radius: 15px;
      border: 2px dashed var(--surface-color-10);
      display: flex;
      width: 56px;
      min-width: 56px;
      height: 56px;
      justify-content: center;
      align-items: center;
      @media (max-width: 599px) {
        width: 44px;
        min-width: 44px;
        height: 44px;
      }

      .v-image {
        margin: 0 !important;
      }
    }
  }

  .unable-to-create {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px dashed var(--surface-color-10);
    border-radius: 10px;
    padding: 12px;
    font-weight: 600;
    font-size: 13px;
    line-height: 130%;
  }

  .v-image {
    margin: 15px 0 10px;
  }
}
</style>
